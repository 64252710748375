import { getRandomRectlist, defaultPreset, renderSvg } from "./lib/lib.mjs";

import Swiper from "swiper";
import OverlayScrollbars from "overlayscrollbars";

const maxIndex = 50;
// Media Query
const mq = window.matchMedia("screen and (min-width: 600px)");

const controls = document.querySelector(".js-interface");
const menu = document.querySelector(".js-menu-button");

// Canvas width
let canvasWidth = mq.matches
  ? (window.innerWidth - controls.offsetWidth) / 4
  : window.innerWidth / 1;
let canvasHeight = mq.matches
  ? (window.innerHeight - menu.offsetHeight) / 4
  : (window.innerHeight - menu.offsetHeight) / 2;

window.addEventListener("resize", () => {
  canvasWidth = mq.matches
    ? (window.innerWidth - controls.offsetWidth) / 5
    : window.innerWidth / 3;
  canvasHeight = mq.matches
    ? (window.innerHeight - menu.offsetHeight) / 5
    : (window.innerHeight - menu.offsetHeight) / 3;
});

class Construction {
  constructor() {
    this.canvasElements = document.querySelectorAll(".js-canvas");
    this.controls = document.querySelector(".js-controls");
    this.console = document.querySelector(".js-console");
    this.canvas = document.querySelector(".js-canvas");
    this.menuButton = document.querySelector(".js-menu-button");
    this.menu = document.querySelector(".js-menu");
    this.menuScroll = document.querySelector(".js-menu-scroll");
    this.menuTitle = document.querySelectorAll(".js-menu-title");
    this.interface = document.querySelectorAll(".js-interface");
    this.expandEditor = document.querySelector(".js-expand-button");
    this.collapseEditor = document.querySelector(".js-collapse-button");
    this.editorContent = document.querySelector(".js-editor-content");
    this.sliderShopPrev = document.querySelector(".js-slider-shop-prev");
    this.sliderShopNext = document.querySelector(".js-slider-shop-next");
    this.sliderExhibitionPrev = document.querySelector(
      ".js-slider-exhibition-prev"
    );
    this.sliderExhibitionNext = document.querySelector(
      ".js-slider-exhibition-next"
    );
    this.a4Button = document.querySelector(".js-a4-button");
    this.a2Button = document.querySelector(".js-a2-button");
    this.generateButton = document.querySelector(".js-generate-button");
    this.variationDesktop = document.querySelector(".js-variation-desktop");
    this.variationMobile = document.querySelector(".js-variation-mobile");
    this.variationMobileTitle = document.querySelector(
      ".js-variation-mobile-title"
    );
    this.sliders = document.querySelectorAll(".range-slider__input");
    this.hint = document.querySelector(".js-hint");
    this.defaultPreset = defaultPreset;
    this.parameterValues = {
      minWidth: 0,
      maxWidth: 1,
      minHeight: 0,
      maxHeight: 1,
      density: 4,
      brightness: 0.5,
      orientation: 0.35,
      variation: 0,
      padding: 50,
      variationDesktop: 0,
    };
    this.frozen = false;
    this.rectlist = getRandomRectlist(50);

    this.initSliders();
    this.initWindow();
    this.update();

    this.a4Button.addEventListener("click", () => {
      this.savePdf(210, 297, this.a4Button);
    });
    this.a2Button.addEventListener("click", () => {
      this.savePdf(420, 594, this.a2Button);
    });

    this.generateButton.addEventListener("click", () => {
      this.rectlist = getRandomRectlist(50);
      this.update();
      this.frozen = false;
    });

    this.variationDesktop.addEventListener("input", () => {
      this.variationMobile.value = this.variationDesktop.value;
    });

    this.variationDesktop.addEventListener("change", () => {
      this.variationMobile.value = this.variationDesktop.value;
    });

    this.variationDesktop.addEventListener("click", () => {
      this.variationMobile.value = this.variationDesktop.value;
    });

    this.menuButton.addEventListener("click", () => {
      // Collapse Editor if mobile
      if (!mq.matches) {
        this.editorContent.classList.remove("interface-content-visible");
        this.expandEditor.innerHTML = "Expand Editor";
        this.controls.classList.add("controls-hidden");
      }
      if (this.menu.classList.contains("active")) {
        this.menu.classList.remove("active");
        this.menuButton.classList.remove("italic");
        this.controls.classList.remove("controls-hidden");
        this.menuTitle.forEach((element) => {
          const content = element.parentNode.children[1];
          content.classList.remove("active");
          element.classList.remove("italic");
        });
      } else {
        this.menu.classList.add("active");
        this.menuButton.classList.add("italic");
      }
    });

    this.expandEditor.addEventListener("click", () => {
      this.expandEditor.classList.add("button-hidden");
      this.variationMobileTitle.classList.add("variation-mobile-title-visible");
      this.collapseEditor.classList.add("button-visible");
      this.editorContent.classList.add("interface-content-visible");
    });

    this.collapseEditor.addEventListener("click", () => {
      this.editorContent.classList.remove("interface-content-visible");
      this.collapseEditor.classList.remove("button-visible");
      this.variationMobileTitle.classList.remove(
        "variation-mobile-title-visible"
      );
      this.expandEditor.classList.remove("button-hidden");
    });

    this.menuTitle.forEach((element, index) => {
      const content = element.parentNode.children[1];
      let others = [].slice.call(this.menuTitle);
      others.splice(index, 1);
      element.addEventListener("click", () => {
        if (content.classList.contains("active")) {
          content.classList.remove("active");
          element.classList.remove("italic");
        } else {
          others.forEach((element) => {
            const content = element.parentNode.children[1];
            content.classList.remove("active");
            element.classList.remove("italic");
          });
          content.classList.add("active");
          element.classList.add("italic");
        }
      });
    });

    const sliderShop = new Swiper(".slider-shop", {
      loop: true,
      observeParents: true,
      observer: true,
      observeSlideChildren: true,
    });

    const sliderExhibition = new Swiper(".slider-exhibition", {
      loop: true,
      observeParents: true,
      observer: true,
      observeSlideChildren: true,
    });

    this.sliderShopPrev.addEventListener("click", () =>
      sliderShop.slidePrev(500)
    );
    this.sliderShopNext.addEventListener("click", () =>
      sliderShop.slideNext(500)
    );

    this.sliderExhibitionPrev.addEventListener("click", () =>
      sliderExhibition.slidePrev(500)
    );
    this.sliderExhibitionNext.addEventListener("click", () =>
      sliderExhibition.slideNext(500)
    );

    // Controls scrollbar
    OverlayScrollbars(this.interface, {
      className: "scrollbar-theme",
      autoUpdate: true,
      scrollbars: {
        autoHide: "never",
      },
    });

    // Show controls on desktop
    if (mq.matches) {
      this.editorContent.classList.add("interface-content-visible");
    }

    try {
      // Chrome, Firefox & Safari 14<
      mq.addEventListener("change", (e) => {
        if (mq.matches) {
          this.editorContent.classList.add("interface-content-visible");
          this.expandEditor.innerHTML = "Collapse Editor";
        } else {
          this.editorContent.classList.remove("interface-content-visible");
          this.expandEditor.innerHTML = "Expand Editor";
        }
      });
    } catch (e1) {
      try {
        // Safari >13
        mq.addListener((e) => {
          if (mq.matches) {
            this.editorContent.classList.add("interface-content-visible");
            this.expandEditor.innerHTML = "Collapse Editor";
          } else {
            this.editorContent.classList.remove("interface-content-visible");
            this.expandEditor.innerHTML = "Expand Editor";
          }
        });
      } catch (e2) {
        console.error(e2);
      }
    }
  }

  get controlsHidden() {
    return this.controls.classList.contains("hidden");
  }

  get orientation() {
    return this.getParameter("orientation");
  }

  set variation(value) {
    this.setParameter("variation", value);
  }

  get preset() {
    const densityFactor = (1 - this.getParameter("density") / 16) * 0.5 + 0.5;

    const minWidth =
      Math.pow(this.getParameter("minWidth"), 2) * densityFactor * 147 + 1;
    const maxWidth =
      Math.pow(this.getParameter("maxWidth"), 2) * densityFactor * 160 + 3;

    const minHeight =
      Math.pow(this.getParameter("minHeight"), 2) * densityFactor * 147 + 1;
    const maxHeight =
      Math.pow(this.getParameter("maxHeight"), 2) * densityFactor * 160 + 3;

    const maxCount = this.getParameter("density");

    const canvasPadding = this.getParameter("padding");

    function getKeyframeValue(frame, keyframes) {
      const index = frame * (keyframes.length - 1);

      const lower = keyframes[Math.floor(index)];
      const upper = keyframes[Math.ceil(index)];
      const diff = upper - lower;
      const value = lower + diff * (index - Math.floor(index));
      return value;
    }

    const keyframes = {
      0: [1, 0, 0, 0, 1],
      45: [0, 1, 0, 0, 0],
      315: [0, 0, 0, 1, 0],
      90: [0, 0, 1, 0, 0],
    };

    const baseRotateAmount = 20;
    const rotate0Amount =
      baseRotateAmount * getKeyframeValue(this.orientation, keyframes[0]);
    const rotate45Amount =
      baseRotateAmount * getKeyframeValue(this.orientation, keyframes[45]);
    const rotate90Amount =
      baseRotateAmount * getKeyframeValue(this.orientation, keyframes[90]);
    const rotate315Amount =
      baseRotateAmount * getKeyframeValue(this.orientation, keyframes[315]);

    const index = Math.floor(maxIndex * this.getParameter("variation"));

    getKeyframeValue(this.getParameter("orientation"), keyframes);

    const colorAmount = Math.pow(this.getParameter("brightness"), 2);
    const minColorCount = 1;

    const preset = {
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      maxCount,
      canvasPadding,
      index,
      colorAmount,
      minColorCount,
      rotate0Amount,
      rotate45Amount,
      rotate90Amount,
      rotate315Amount,
    };
    return preset;
  }

  initSliders() {
    this.sliders.forEach((slider) => {
      const name = slider.name;
      const value = this.parameterValues[name];

      slider.value = value;

      slider.addEventListener("click", () => {
        this.frozen = true;
      });

      slider.addEventListener("change", () => {
        this.frozen = true;
      });

      slider.addEventListener("input", () => {
        this.update();
      });

      slider.addEventListener("mousedown", () => {
        slider.style.cursor = "grabbing";
      });

      slider.addEventListener("mouseup", () => {
        slider.style.cursor = "grab";
      });
    });
  }

  initWindow() {
    window.addEventListener("resize", () => {
      this.update();
    });
  }

  update() {
    const urlPrefix = "data:image/svg+xml;utf8,";
    const newPreset = Object.assign({}, this.defaultPreset, this.preset);

    const text = Object.values(newPreset)
      .map((num) => {
        let value;
        if (!num.isInteger && num < 1 && num > 0) {
          value = Math.round(num * 10);
        } else {
          value = Math.round(num);
        }
        return value;
      })
      .join(", ");

    this.console.textContent = text;

    this.canvasElements.forEach((canvas) => {
      canvas.src =
        urlPrefix +
        renderSvg(this.rectlist, newPreset, canvasWidth, canvasHeight);
    });
  }

  get svg() {
    const newPreset = Object.assign({}, this.defaultPreset, this.preset);
    return renderSvg(this.rectlist, newPreset, canvasWidth, canvasHeight);
  }

  savePdf(width, height, targetButton) {
    targetButton.setAttribute("disabled", true);
    targetButton.classList.add("loading");
    import("./savePdf").then((module) => {
      const savePdf = module.default;
      savePdf(this.svg, width, height).then(() => {
        targetButton.removeAttribute("disabled");
        targetButton.classList.remove("loading");
      });
    });
  }

  getParameter(name) {
    return parseFloat(document.querySelector(`[name="${name}"]`).value);
  }

  setParameter(name, value) {
    document.querySelector(`[name="${name}"]`).value = value;
    this.update();
  }
}

new Construction();
